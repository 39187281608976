/* eslint-disable max-len */
/*
 * @Author: Nanxing Xiang
 * @Date: 2021-02-02 17:01:32
 * @LastEditors: 向南星 xiangnanxing@scmagic.cn
 * @LastEditTime: 2023-03-20 14:48:34
 * @Description: file content
 */
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { EasErrorBoundary, EasLoading } from '@easyvaas/react-components';
import createReactRoutes from '@easyvaas/create-react-routes';
import remfix from '@easyvaas/rem-fixable';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { appInfoSelector } from './store/selector';

remfix(750, 75);
const basename = process.env.REACT_APP_ENV === 'qa' ? '/download/' : '/v2/';
const Routes = createReactRoutes({
  'c/:cid': lazy(() => import('src/modules/channel/views/v1')),
  'c2/:cid': lazy(() => import('src/modules/channel/views/v2')),
  'c3/:cid': lazy(() => import('src/modules/channel/views/v3')),
  'c4/:cid': lazy(() => import('src/modules/channel/views/v5')),
  'c5/:cid': lazy(() => import('src/modules/channel/views/v5')),
  'c6/:cid': lazy(() => import('src/modules/channel/views/v4')),
  'c7/:cid': lazy(() => import('src/modules/channel/views/v6')),
  'c8/:cid': lazy(() => import('src/modules/channel/views/v8')),
  'guides/:type': lazy(() => import('src/modules/guides')),
  'channel/:key': lazy(() => import('src/modules/vest')),
  invitation: lazy(() => import('src/modules/invitation')),
  download: lazy(() => import('src/views/YzbDownload')),
  questions: lazy(() => import('src/modules/questions')),
  'type/:appname': lazy(() => import('src/modules/type')),
  ios_instruction: lazy(() => import('src/views/IOSInstruction')),
  ios_oversea: lazy(() => import('src/views/IOSOverseaDownload')),
  ios_beta_download: lazy(() => import('src/views/IOSBetaDownload')),
  recommends: lazy(() => import('src/views/Recommends')),
  'third-party/qiezi': lazy(() => import('src/views/ThirdParty/QieZi')),
  'download/:appname': lazy(() => import('src/views/AutoDownload')),
  'pc/:type': lazy(() => import('src/views/PCDownload')),
  collection: lazy(() => import('src/views/Collection'))
});
const App: React.FC = () => {
  useRecoilValue(appInfoSelector);
  return <Routes />;
};

const Wrapper = () => {
  return (
    <EasErrorBoundary>
      <RecoilRoot>
        <BrowserRouter basename={basename}>
          <Suspense fallback={<EasLoading type="dots" />}>
            <App />
          </Suspense>
        </BrowserRouter>
      </RecoilRoot>
    </EasErrorBoundary>
  );
};

ReactDOM.render(<Wrapper />, document.getElementById('root'));
