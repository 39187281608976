/*
 * @Author: Nanxing Xiang
 * @Date: 2021-01-01 18:08:51
 * @LastEditors: Nanxing Xiang
 * @LastEditTime: 2021-04-15 11:43:43
 * @Description: file content
 */
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const getAgent = () => {
  const appInfo = sessionStorage.getItem('appInfo');
  if (!appInfo) return null;
  const info = JSON.parse(appInfo);
  const appname = info.appName;
  return `${appname || 'yizhibo'} v5.12.0 rv:20201021 ${
    process.env.REACT_APP_ENV === 'prod' ? 'Online' : 'Development'
  } (h5) ${navigator.userAgent}`;
};

export const requestInterceptor = (conf: AxiosRequestConfig) => {
  const query = new URLSearchParams(window.location.search.slice());
  const sessionid = query.get('sessionid');
  const config = { ...conf };
  if (config.baseURL && /h5/.test(config.baseURL)) {
    config.headers['Custom-Agent'] = getAgent();
    config.headers['EL-AUTH'] = sessionid;
    config.headers['EL-HOST'] = window.location.host;
  } else {
    config.headers['EL-HOST'] = window.location.host;
    config.params.sessionid = sessionid;
  }
  return config;
};

interface AppResposnseData {
  retval: 'ok' | string;
  retinfo: any;
  reterr: string;
}

const instanceOfAppResponse = (
  obj: AxiosResponse
): obj is AxiosResponse<AppResposnseData> =>
  !obj.config.baseURL || !/h5/.test(obj.config.baseURL);

export const responseInterceptor = (
  res: AxiosResponse<AppResposnseData | any>
): AxiosResponse => {
  if (instanceOfAppResponse(res)) {
    if (res.data.retval !== 'ok') {
      throw new Error(res.data.reterr || res.data.retval);
    }
    return { ...res, data: res.data.retinfo };
  }
  return res;
};

export const errorInterceptor = (err: any) => {
  console.log(err);
  const { response } = err;
  if (response && response.data.code) {
    throw new Error(response.data.message || response.data.code);
  } else {
    throw new Error('服务器错误,请刷新重试');
  }
};
