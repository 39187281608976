import { selector } from 'recoil';
import { fetchAppInfo } from 'src/services';

export const appInfoSelector = selector({
  key: 'AppInfoQuery',
  get: async () => {
    const info = await fetchAppInfo({});
    info.appName = info.appName || 'yizhibo';
    sessionStorage.setItem('appInfo', JSON.stringify(info));
    return info;
  }
});
