/*
 * @Author: Nanxing Xiang
 * @LastEditors: 向南星 xiangnanxing@scmagic.cn
 * @Date: 2020-04-29 10:11:18
 * @LastEditTime: 2023-01-11 17:23:23
 * @Description:
 * @FilePath: \easylive-download\src\config\index.ts
 */
import devConfig from './config.dev';
import onlineConfig from './config.online';
import qaConfig from './config.qa';

const defaultConfig: Omit<Config, 'url'> = {
  api: {
    activity: '',
    appgw: '',
    h5: '',
    service: ''
  },
  apps: {
    yizhibo: { title: '音九' },
    ellite: { title: '音九' },
    eplive: { title: '右手' },
    rhlive: { title: '右手' },
    ymlive: { title: '幺妹直播' },
    magiclive: { title: '哇塞直播' },
    oplive: { title: '音九' },
    fadelive: { title: 'Fade直播' },
    yibo: { title: '知足' },
    easylove: { title: '易恋' },
    sizhibo: { title: '丝直播' },
    xsports: { title: '星系体育' },
    yipinlive: { title: '音九' },
    oversea: { title: 'LiveU' },
    ylivetool: { title: 'Y直播工具' },
    sizulive: { title: '丝足直播' },
    zujilive: { title: '音九直播' },
    furolive: { title: '芙蓉直播' },
    dragonlive: { title: '龙直播' },
    footlive: { title: '茗莲' },
    onelive: { title: '音九' },
    wmlive: { title: '爱丝悦' },
    hola: { title: '初见' },
    miy: { title: '蜜Y' },
    gargantua: { title: '来乐' },
    enjoy: { title: '音九' },
    injoy: { title: '集乐' },
    jile: { title: '集乐' },
    funtime: { title: '集乐' }
  }
};

const getConifg = () => {
  if (process.env.REACT_APP_ENV === 'qa')
    return { ...defaultConfig, ...qaConfig };
  if (process.env.REACT_APP_ENV === 'prod')
    return { ...defaultConfig, ...onlineConfig };
  return { ...defaultConfig, ...devConfig };
};
const config = getConifg();
export default config;
