import domains from './domains';

/*
 * @Author: Nanxing Xiang
 * @LastEditors: Nanxing Xiang
 * @Date: 2020-04-29 10:11:18
 * @LastEditTime: 2021-03-15 17:22:35
 * @Description:
 * @FilePath: \easylive-download\src\config\config.dev.ts
 */
const devConfig: Omit<Config, 'apps'> = {
  api: {
    activity: '/activity',
    appgw: '/appgw',
    h5: '/h5',
    service: '/service'
  },
  url: {
    DOWNLOAD_URL: `https://dev.${domains.DEV_DOMAIN}/download`
  }
};

export default devConfig;
