/*
 * @Author: NanXing Xiang
 * @Date: 2021-04-10 22:27:08
 * @LastEditors: Nanxing Xiang
 * @LastEditTime: 2021-12-31 13:51:50
 */
import config from 'src/config';
import request from 'src/utils/service/v2/request';
import { DownloadSetting, ChannelDownloadData, AppInfo } from './types';

export const fetchChannelDownloadData = request<any, ChannelDownloadData>({
  baseURL: config.api.h5,
  url: '/easylive/channel/package/url',
  method: 'GET'
});

export const fetchDownloadSetting = request<any, DownloadSetting>({
  baseURL: config.api.h5,
  method: 'GET',
  url: '/easylive/channel/app/download'
});

export const reportIosCodeDownload = request({
  baseURL: config.api.h5,
  method: 'POST',
  url: '/easylive/channel/ios/code/use'
});

export const fetchAppInfo = request<any, AppInfo>({
  baseURL: config.api.h5,
  url: '/general/app/info',
  method: 'GET'
});
