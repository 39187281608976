import domains from './domains';

/*
 * @Author: Nanxing Xiang
 * @LastEditors: Nanxing Xiang
 * @Date: 2020-07-03 11:14:17
 * @LastEditTime: 2021-03-15 17:23:00
 * @Description:
 * @FilePath: \easylive-download\src\config\config.qa.ts
 */
const qaConfig: Omit<Config, 'apps'> = {
  api: {
    activity: '/support/interface/activity',
    appgw: '/appgw',
    h5: '/gateway/h5',
    service: '/user/xiangnanxing/mobile/proxy/support/service'
  },
  url: {
    DOWNLOAD_URL: `https://dev.${domains.DEV_DOMAIN}/download`
  }
};

export default qaConfig;
