/*
 * @Author: Nanxing Xiang
 * @Date: 2021-03-31 16:02:24
 * @LastEditors: Nanxing Xiang
 * @LastEditTime: 2021-03-31 16:04:10
 * @Description: file content
 */
import axios, { AxiosRequestConfig } from 'axios';
import {
  errorInterceptor,
  requestInterceptor,
  responseInterceptor
} from './interceptors';

const instance = axios.create();
instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(responseInterceptor, errorInterceptor);

interface RequestOptions {
  baseURL: string;
  url: string;
  method: AxiosRequestConfig['method'];
  loading?: string;
}
const request = <P = any, R = any>(options: RequestOptions) => async (
  params: P
): Promise<R> => {
  const res = await instance({ ...options, params });
  return res.data;
};

export default request;
